.notifications h2 {
  margin-left: 42px;
}

.notifications li {
  margin-top: 2px;
  padding: 16px 6px;
  background: rgba(255, 0, 77, 0.1);
  cursor: pointer;
}

.notifications a {
  color: #FFFFFF;
}

.notifications li svg {
  width: 18px;
  height: 18px;
  margin-right: 12px;
  margin-left: 6px;
  vertical-align: top;
}

.notifications li.read {
  background: rgba(0, 0, 0, 0);
}

.timestamp {
  display: block;
  font-size: 12px;
  margin-left: 36px
}
