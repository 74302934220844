.group {
  color: rgba(255, 255, 255, 1);
  margin-bottom: 40px;
}

.label {
  font-size: 17px;
  font-weight: bold;
}

.input {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 48px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.07);
  margin-bottom: 5px;
}

.input input {
  flex-grow: 1;
  color: rgba(255, 255, 255, 0.8);
  cursor: text;
}

input[type="file"] {
  display: none;
}

.icon, .pre {
  margin-right: 10px;
}

.error {
  color: rgba(255, 0, 0, 1);
}

.error .input {
  border-color: rgba(255, 0, 0, 1);
}

.errorMsg {
  display: none;
  font-size: 14px;
}

.error .errorMsg {
  display: block;
}
