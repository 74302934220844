.button {
  color: rgb(255, 255, 255);
  height: 40px;
  line-height: 40px;
  padding: 0px 22px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 40px;
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  vertical-align: middle;
}

.button:hover {
  background-color: rgba(255, 255, 255, 0.08);
  border-color: rgba(255, 255, 255, 0.18);
}

.button svg {
  height: 16px;
  margin-left: -8px;
  margin-right: 6px;
  margin: -4px 6px 0px -8px;
  vertical-align: middle;
}

@media (max-width: 768px) {
  .button {
    height: 30px;
    line-height: 30px;
  }
}
