.group {
  color: rgba(255, 255, 255, 1);
  margin-bottom: 40px;
}

.label {
  font-size: 17px;
  font-weight: bold;
}

.textarea {
  padding-top: 14px;
  padding-bottom: 7px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.07);
}

.textarea textarea {
  width: 100%;
  border-width: 0px;
  color: rgba(255, 255, 255, 0.8);
  resize: none;
  cursor: text;
}

.error {
  color: rgba(255, 0, 0, 1);
}

.error .input {
  border-color: rgba(255, 0, 0, 1);
}

.errorMsg {
  display: none;
  font-size: 14px;
}

.error .errorMsg {
  display: block;
}
