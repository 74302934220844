.layout {
  width: 150px;
  height: 150px;
  border-radius: 100%;
}

.border {
  composes: layout;
  border: 3px solid red;
  padding: 6px 6px;
}

.image {
  composes: layout;
  object-fit: cover;
}

.small .layout {
  width: 40px;
  height: 40px;
}

.small .border {
  border-width: 1px;
  padding: 2px 2px;
}

@media (max-width: 768px) {
  .layout {
    width: 77px;
    height: 77px;
  }
}
