.loader {
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  border-width: 3px;
  margin: auto;
  animation: 0.66s linear 0s infinite normal none running rotate-loader;
  border-style: solid;
  border-color: rgb(41, 41, 41) rgb(41, 41, 41) rgb(41, 41, 41) rgb(255, 0, 0);
  user-select: none;
}

@keyframes rotate-loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
