.wrapper {
  position: relative;
}

.button {
  height: 42px;
  width: 42px;
  border-radius: 40px;
  padding: 0px 14px;
  background: rgba(255, 0, 77, 0.2);
  color: rgb(255, 0, 77);
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: rgb(255, 87, 87);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 4px;
  top: -4px;
  z-index: 10;
  overflow: hidden;
}

.badge span {
    font-size: 10px;
    font-weight: bold;
    color: white;
    vertical-align: middle;;
}

@media (max-width: 768px) {
  .button {
    height: 30px;
    line-height: 30px;
  }
}
