@import-normalize;

body {
  margin: 0;
  background: rgb(18, 18, 18);
  font-family: 'Courier New';
  color: white;
  min-height: 100vh;
  display: flex;
}

button, input, textarea {
  outline: none;
  border: none;
  user-select: none;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  background: transparent;
}

ul, li {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
