.app {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.header {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 24px;
  max-width: 720px;
}



h2, h3 {
  color: white;
  font-family: 'Courier New';
  margin-bottom: 6px;
}

h3 {
  margin-top: 0px;
  font-weight: normal;
}

main {
  width: 100%;
  max-width: 800px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

@media (max-width: 768px) {
  main {
    margin: 0px 16px;
    width: auto;
  }
}
