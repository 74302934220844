.card {
  width: 100%;
  max-width: 230px;
  flex: 1 1 40%;
  padding: 12px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.image {
  width: 100%;
  flex: 1 1 40%;
  position: relative;
}

.image:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.image img {
  border-radius: 6px;
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  user-select: none;
}

.meta {
  margin: 24px 0px 12px;
  position: relative;
}

.meta .title {
  font-weight: bold;
  margin-bottom: 6px;
}

.like {
  position: absolute;
  bottom: -5px;
  right: 10px;
  height: 32px;
  border-radius: 12px;
  padding: 6px 12px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;;
  color: white;
  font-size: 14px;
  vertical-align: middle;
}

.like svg {
  height: 16px;
  width: 16px;
  margin-right: 10px;
}

.like:hover {
  background: rgb(41, 41, 41);
}
