.list {
  width: 100%;
  max-width: 400px;
  color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  margin: 16px auto;
}

.link {
  text-decoration: none;
  color: rgba(255, 255, 255, 1);
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 6px 0px;
}

.text {
  margin: 0px 20px;
  flex-grow: 1;
}

.primary {
  font-weight: bold;
}
