.edit {
  margin-bottom: 48px;
}

.edit p {
  font-size: 22px;
}

.edit form {
  max-width: 400px;
}

.column {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin: 0 auto;
  gap: 48px;
}

.dropzone {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 150px;
  width: 150px;
  padding: 4px;
  cursor: pointer;
  border: 3px dashed rgb(204, 204, 204);
  border-radius: 100%;
}

.dropzone p {
  font-size:  14px;
}

.dropzone:hover {
  background-color: rgba(255, 255, 255, 0.08);
}

.preview {
  border-radius: 100%;
  object-fit: cover;
  width: 150px;
  height: 150px
}

@media (max-width: 768px) {
  .column {
    flex-direction: column;
  }
}
