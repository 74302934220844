.tabBar {
  font-family: 'Courier New';
  padding: 0px 32px 0px;
  color: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  border-top: 1px solid rgb(41, 41, 41);
}

.tabBar a {
  padding: 16px 24px 16px;
  cursor: pointer;
  user-select: none;
  color: white;
  text-decoration: none;
  border-top: 2px solid transparent;
}

.tabBar :global(.active) {
  color: white;
  border-top: 2px solid white;
}
