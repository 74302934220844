.footer {
  width: 100%;
  max-width: 800px;
  margin: 48px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.footer .copyright {
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  font-weight: normal;
  flex-grow: 1;
  flex-shrink: 0;
}

.footer .links {
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
}

.footer a {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  font-weight: bold;
  margin-right: 16px;
}

@media (max-width: 768px) {
  .footer {
    text-align: center;
    justify-content: center;
  }
  .links {

  }
  .copyright {
    margin-top: 16px;
    order: 2;
  }
}
