.blur {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(18, 18, 18, 0.7);
  backdrop-filter: blur(20px);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background: rgba(0, 0, 0, 0.9);
  width: 100%;
  max-width: 320px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 24px;
}

.user {
  display: flex;
  margin: 24px 0px;
}

.username {
  margin-left: 16px;
  font-weight: bold;
}

.address {
  font-weight: normal;
  font-size: 14px;
}
