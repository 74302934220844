.header {
  display: flex;
  flex-direction: row;
  color: white;
  font-family: 'Courier New';
  margin: 16px 16px 16px;
}

.avatar {
  margin-right: 24px;
}

.meta {
  display: flex;
  flex-direction: column;
}

.username {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
}

.username svg {
  margin-right: 10px;
}

.username h1 {
  display: flex;
  font-size: 36px;
  font-weight: bold;
  margin: 0px 20px 0px 0px;
}

.username h1 a {
  text-decoration: none;
  color: rgb(255, 255, 255);
}

.address {
  flex-basis: 100%;
  margin-top: 10px;
}

.stats {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.stats a {
  background-color: transparent;
  color: rgb(255, 255, 255);
  padding: 0;
  margin-right: 24px;
  text-decoration: none;
}

.bio {
  white-space: pre-wrap;
  line-height: 1.5;
}

.links {
  margin: 20px 0px;
}

.links a {
  margin-right: 20px;
  color: white;
  font-weight: bold;
  text-decoration: none;
  white-space: nowrap;
}

.links svg {
  margin-right: 10px;
  vertical-align: middle;
}

.links span {
  vertical-align: middle;
}

.copy {
  margin-left: 6px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.5);
  vertical-align: middle;
}

.copy:hover {
  color: rgba(255, 255, 255, 0.9)

}

@media (max-width: 768px) {
  .header {
    font-size: 14px;
  }

  .username h1 {
    font-size: 24px;
  }
}
