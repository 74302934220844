.gallery {
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
  margin-top: 28px;
}

.card {
  width: 100%;
  max-width: 230px;
  flex: 1 1 40%;
  padding: 12px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.image {
  width: 100%;
  flex: 1 1 40%;
  position: relative;
}

.image:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.image img {
  border-radius: 6px;
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  user-select: none;
}

.meta {
  margin: 24px 0px 12px;
  position: relative;
}

.meta .title {
  font-weight: bold;
  margin-bottom: 6px;
}
