.form {
  width: 100%;
  max-width: 400px;
  margin: auto;
  flex-grow: 1;
}

.column {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin: 0 auto;
  width: 100%;
  gap: 24px;
}

.dropzone {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  min-height: 150px;
  width: 300px;
  padding: 4px;
  cursor: pointer;
  border: 3px dashed rgb(204, 204, 204);
}

.dropzone p {
  font-size:  14px;
}

.dropzone:hover {
  background-color: rgba(255, 255, 255, 0.08);
}

.preview {
  border-radius: 6px;
  width: 300px;
}

.imageError {
  color: red;
}

.fee {
  margin-top: -24px;
  margin-bottom: 40px;
  color: rgba(255, 255, 255, 0.4);
  font-size: 14px;
}

.fee span {
  color: rgba(255, 255, 255, 1);
  font-weight: bold;
}
