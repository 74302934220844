.action {
  cursor: pointer;
}

.action:hover .dropdown {
  display: block;
}

.dropdown {
  position: relative;
  user-select: none;
  display: none;
}

.content {
  position: absolute;
  background-color: rgb(31, 31, 31);
  right: 0;
  border-radius: 10px;
  width: 200px;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 24px 24px;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  cursor: pointer;
}

.item svg {
  margin-right: 10px;
}

.item img {
  width: 16px;
  height: 16px;
  margin-right: 12px;
  vertical-align: middle;
}
