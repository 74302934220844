.asset {
  display: flex;
  flex-direction: row;
  margin: 24px 0;
}

.image {
  max-width: 50%;
}

.details {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 0px 12px 0px 24px;
}

.header {
  display: flex;
  flex-direction: row;
}

.header h1 {
  margin: 0;
  flex-grow: 1;
}
