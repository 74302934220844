.header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(18, 18, 18, 0.7);
  backdrop-filter: blur(20px);
  z-index: 1000;
}

.bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  max-width: 800px;
  margin: auto;
}

.logo {
  width: 100%;
}

.logo img {
  max-height: 58px;
  margin-right: 24px;

}

.bar button {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .logo {
    height: 48px;
  }
}

/*
.search {
  color: rgb(140, 140, 140);
  background: rgba(255, 255, 255, 0.07);
  border: 1px solid transparent;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 24px;
  padding: 0px 16px;
}

.search:hover {
  border-color: rgba(255, 255, 255, 0.1);
}

.search:focus-within {
  background: rgba(255, 255, 255, 0);
  border-color: rgba(255, 255, 255, 0.2);
  box-shadow: rgb(255 255 255 / 6%) 0px 0px 0px 4px;
}

.search button {
  margin-left: 10px;
  outline: none;
  background: transparent;
  border: none;
  cursor: pointer;
  vertical-align: middle;
}

.search button svg {
  height: 16px;
  width: 16px;
  vertical-align: middle;
}

.search svg {
  color: rgb(140, 140, 140);
  height: 14px;
  width: 14px;
}

.header input {
  display: flex;
  outline: none;
  color: rgba(255, 255, 255, 0.8);
  font-size: 15px;
  border-width: 0px;
  margin-left: 10px;
  padding: 0px;
  background-color: transparent;
  -webkit-appearance: none;
  font-family: 'Courier New';
  width: 280px;
}

.icon {
  align-items: flex-end;
  margin-right: 8px;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 0, 77, 0.2);
  border-radius: 100%;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

*/
